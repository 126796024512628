import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import styles from '../styles'

const HeaderContainer = styled.header`
  width: 20%;

  * {
    margin: 0;
    padding: 0;
  }
`

const HeaderDiv = styled.div`
  background: ${styles.paper};
  height: 100%;
  padding: 1em 1em;
  width: 60%;
`

const HeaderList = styled.ul`
  list-style: none;
  text-align: left;
`

const HeaderItem = styled.li`
  margin: 2em 0;

  a {
    color: ${styles.dustyDark};
    text-decoration: none;

    &:link {}

    &:active {}
  }
`

const LinkHeading = styled.h2`
  box-sizing: border-box;
  padding: 0.2em 0.5em;

  &:hover {
    background: ${styles.dustyLight};
    transition: background 0.3s ease-in;
  }
`

const HeaderLink = ({ content, page }) => {
  return (
    <HeaderItem>
      <Link to={page}>
        <LinkHeading>{content}</LinkHeading>
      </Link>
    </HeaderItem>
  )
}

const Header = () => (
  <HeaderContainer>
    <HeaderDiv>
      <HeaderList>
        <HeaderLink
          content='me'
          page='/'
        />
        <HeaderLink
          content='resume'
          page='/resume'
        />
        <HeaderLink
          content='portfolio'
          page='/portfolio'
        />
        { /*
        <HeaderLink
          content='blog'
          page='/blog'
        />
       */ }
      </HeaderList>
    </HeaderDiv>
  </HeaderContainer>
)

export default Header
