import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Header from './header'
import Footer from './footer'
import styles from '../styles'
import './layout.css'

const LayoutContainer = styled.div`
  background: ${styles.paperLight};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
`

const LayoutDiv = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: stretch;
`

const LayoutChild = styled.main`
  background: ${styles.paperLight};
  margin: 1em;
  padding: 1em;
  width: 80%;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <LayoutContainer>
          <LayoutDiv>
            <LayoutChild>
              {children}
            </LayoutChild>
            <Header />
          </LayoutDiv>
          <Footer />
        </LayoutContainer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
