module.exports = {
  // primary-bg
  paper: '#D5D5D5',
  paperDark: '#DCDCDC',
  paperLight: '#EEEEEE',
  // secondary-bg
  lavender: '#9099A2',
  lavenderDark: '#778089',
  lavenderLight: '#a9b2bb',
  // text
  overcast: '#6D7993',
  overcastDark: '#54607a',
  overcastLight: '#8692ac',
  // hightlight
  dusty: '#96858F',
  dustyDark: '#7d6c76',
  dustyLight: '#af9ea8',
}
