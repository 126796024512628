import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import styles from '../styles'

const TopElement = styled.div`
  margin: -2em -2em 2em -2em;
  padding: 2em;
  background: ${styles.lavenderLight};
`

const PortfolioPage = () => (
  <Layout>
    <TopElement>Portfolio</TopElement>
    <h1>Lorm Ipsum</h1>
    <p>Temporary Content</p>
  </Layout>
)

export default PortfolioPage
