import React from 'react'
import styled from 'styled-components'

import styles from '../styles'

const FooterContainer = styled.footer`
  background: ${styles.lavender};
  height: 3%;
  width: 100%;
`

const FooterList = styled.ul`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-around;
  padding: 0.5em 10%;
  margin-right: 20%;
`

const FooterItem = styled.li`
  border-bottom: 2px solid rgba(0,0,0,0);
  color: ${styles.paperLight};
  list-style: none;


  &:hover {
    border-bottom: 2px solid ${styles.paperLight};
    transition: border-bottom 0.3s ease-in;
  }

  a {
    color: ${styles.paperLight};
    text-decoration: none;
  }
`

const TWITTER_URL = 'https://twitter.com/PearKey'
const LINKEDIN_URL = 'https://www.linkedin.com/in/mikepfarrell/'
const GITHUB_URL = 'https://github.com/michfarr'

const Footer = () => {
  return (
    <FooterContainer>
      <FooterList>
        <FooterItem><a href={ TWITTER_URL }>Twitter</a></FooterItem>
        <FooterItem><a href={ LINKEDIN_URL }>Linkedin</a></FooterItem>
        <FooterItem><a href={ GITHUB_URL }>GitHub</a></FooterItem>
      </FooterList>
    </FooterContainer>
  )
}

export default Footer
